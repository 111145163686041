import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout/Layout'
import styles from './project-page.module.sass'
import {Helmet} from "react-helmet";


export class ProjectTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectHeaderHeight: 0
        };
        this.tagsItem = this.props.frontmatter.tags.map((name, index) =>
            <span>{index !== 0 && ' + '}{name}</span>
        );
        this.clientsItem = null
        if (this.props.fields.client) {
            this.clientsItem = this.props.fields.client.map((client) =>
                <li><span
                    className={styles.projectName}>{client.frontmatter.name}</span> {client.frontmatter.description && '-'} {client.frontmatter.description &&
                <span>{client.frontmatter.description}</span>}</li>
            );
        }

        this.partnersItem = null
        if (this.props.fields.partners) {
            this.partnersItem = this.props.fields.partners.map((partner) =>
                <li><span
                    className={styles.projectName}>{partner.frontmatter.name}</span> {partner.frontmatter.description && '-'} {partner.frontmatter.description &&
                <span>{partner.frontmatter.description}</span>}</li>
            );
        }
        this.imagesItem = null
        if (this.props.frontmatter.images) {
            this.imagesItem = this.props.frontmatter.images.map((image) => {
                if (image) {
                    return <img className={styles.image} src={image.publicURL} alt=""/>
                }
            }
            );
        }
        this.projectHeader = React.createRef()
    }

    componentDidMount() {
        if (window.innerWidth > 992) {
            this.setState({
                projectHeaderHeight: this.projectHeader.current.clientHeight
            })
        }
    }

    render() {
        return (
            <div className={styles.projectPage}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>↘ {this.props.frontmatter.title} ↪ cocktail — atelier ∆ Bayonne</title>
                    <meta name="description" content="Lucie Lafitte & Chloé Serieys ⇆ Atelier multidisciplinaire / design graphique, fabrication, pédagogie, édition, coordination." />
                </Helmet>
                <h3 className={styles.showMobile}> {this.props.frontmatter.title}</h3>
                <div className={styles.scrollContainer}>
                    <div ref={this.projectHeader} className={styles.projectHeader}
                         style={{background: this.props.frontmatter.color}}>
                        <h3 className={styles.hiddenMobile}> {this.props.frontmatter.title}</h3>
                        {this.props.frontmatter.link &&
                        <a className={styles.link} target="_blank"
                           href={this.props.frontmatter.link}> {this.props.frontmatter.link} </a>}
                        <div>{this.tagsItem}</div>
                        <span>∆ — {this.props.frontmatter.date}</span>
                        <span>↶</span>
                        <ul>{this.clientsItem}</ul>
                        <br/>
                    </div>
                    <div className={styles.desktopScrollContainer}>
                        <div className={styles.projectContent}>
                            <div className={styles.innerProjectContent}
                                 style={{marginTop: this.state.projectHeaderHeight}}>
                                <div className={styles.description} dangerouslySetInnerHTML={{ __html: this.props.frontmatter.description}}></div>
                                <br/>
                                {this.partnersItem && <ul>
                                    <li>↘ Avec ↙</li>
                                    {this.partnersItem}
                                </ul>}
                                <br/>
                            </div>
                        </div>
                        <div className={styles.imageListContainer}>
                            {this.imagesItem}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProjectTemplate
    .propTypes = {
    title: PropTypes.string,
}

const
    ProjectPage = ({data}) => {
        const {frontmatter, fields} = data.markdownRemark
        return (
            <Layout background={frontmatter.color}>
                <ProjectTemplate
                    frontmatter={frontmatter}
                    fields={fields}
                />
            </Layout>
        )
    }

ProjectPage
    .propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default ProjectPage

export const
    pageQuery = graphql`
  query ProjectTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
          slug
          client {
            frontmatter {
              name
              link
              description
            }
          }
          partners {
            frontmatter {
              link
              name
              description
            }
          }
        }
        frontmatter {
          description
          date
          color
          arrowColor
          images {
            publicURL
          }
          link
          title
          tags
        }
        }
    }`

// import React from 'react'
// import PropTypes from 'prop-types'
// import {graphql} from 'gatsby'
// import Layout from '../components/Layout/Layout'
// import styles from './project-page.module.sass'
//
//
// export const ProjectTemplate = ({
//                                     frontmatter,
//                                     fields,
//                                 }) => {
//
//     const tagsItem = frontmatter.tags.map((name, index) =>
//         <span>{index !== 0 && ' + '}{name}</span>
//     );
//     let clientsItem = null
//     if (fields.client) {
//         clientsItem = fields.client.map((client) =>
//             <li><span
//                 className={styles.projectName}>{client.frontmatter.name}</span> {client.frontmatter.description && '-'} {client.frontmatter.description &&
//             <span>{client.frontmatter.description}</span>}</li>
//         );
//     }
//
//     let partnersItem = null
//     if (fields.partners) {
//         partnersItem = fields.partners.map((partner) =>
//             <li><span
//                 className={styles.projectName}>{partner.frontmatter.name}</span> {partner.frontmatter.description && '-'} {partner.frontmatter.description &&
//             <span>{partner.frontmatter.description}</span>}</li>
//         );
//     }
//     let imagesItem = null
//     if (frontmatter.images) {
//         imagesItem = frontmatter.images.map((image) =>
//             <img className={styles.image} src={image.publicURL} alt=""/>
//         );
//     }
//     let projectHeader = React.createRef()
//
//     return (
//         <div className={styles.projectPage}>
//             <h3 className={styles.showMobile}> {frontmatter.title}</h3>
//             <div className={styles.scrollContainer}>
//                 <div ref={projectHeader}  className={styles.projectHeader} style={{background: frontmatter.color}}>
//                     <h3 className={styles.hiddenMobile}> {frontmatter.title}</h3>
//                     {frontmatter.link &&
//                     <a className={styles.link} target="_blank" href={frontmatter.link}> {frontmatter.link} </a>}
//                     <div>{tagsItem}</div>
//                     <span>∆ — {frontmatter.date}</span>
//                     <span>↶</span>
//                     <ul>{clientsItem}</ul>
//                     <br/>
//                 </div>
//                 <div className={styles.desktopScrollContainer}>
//                     <div className={styles.projectContent}>
//                         <div className={styles.innerProjectContent}>
//                             <p className={styles.description}>{frontmatter.description}</p>
//                             <br/>
//                             {partnersItem && <ul>
//                                 <li>↘ Avec ↙</li>
//                                 {partnersItem}
//                             </ul>}
//                             <br/>
//                         </div>
//                     </div>
//                     <div className={styles.imageListContainer}>
//                         {imagesItem}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
//
// ProjectTemplate.propTypes = {
//     title: PropTypes.string,
// }
//
// const ProjectPage = ({data}) => {
//     const {frontmatter, fields} = data.markdownRemark
//     return (
//         <Layout background={frontmatter.color}>
//             <ProjectTemplate
//                 frontmatter={frontmatter}
//                 fields={fields}
//             />
//         </Layout>
//     )
// }
//
// ProjectPage.propTypes = {
//     data: PropTypes.shape({
//         markdownRemark: PropTypes.shape({
//             frontmatter: PropTypes.object,
//         }),
//     }),
// }
//
// export default ProjectPage
//
// export const pageQuery = graphql`
//   query ProjectTemplate($id: String!) {
//     markdownRemark(id: { eq: $id }) {
//       fields {
//           slug
//           client {
//             frontmatter {
//               name
//               link
//               description
//             }
//           }
//           partners {
//             frontmatter {
//               link
//               name
//               description
//             }
//           }
//         }
//         frontmatter {
//           description
//           date
//           color
//           arrowColor
//           images {
//             publicURL
//           }
//           link
//           title
//           tags
//         }
//         }
//     }`
